<template>
  <div class="divlistcolo">
    <!-- <button @click="qpp">1</button> -->
    <!-- 报名表 -->

    <!-- 报名表 -->
    <div class="baoming">
      <!-- 添加 -->
      <div class="data">
        <div class="title">添加字段</div>
        <!-- 添加的字段 -->
        <div class="field" v-for="item in list" :key="item.sortid">
          <div class="fil" @click="itemlist(item.sortid)">{{ item.title }}</div>
        </div>
      </div>
      <!-- 展示 -->
      <div class="exhibition">
        <div class="title">报名表内容</div>
        <!-- 展示的字段 -->
        <div class="wited" v-for="item in signup" :key="item.sortid">
          <!-- 输入 -->
          <div class="input" v-if="item.metaelementid == 3">
            <div class="bluecolor">
              {{ item.name }}
              <span v-if="item.must === 1" class="redif">(必填)</span>
            </div>
            <input
              ref="textlist"
              class="inputtodo"
              type="text"
              v-model="item.value"
              disabled
            />
          </div>
          <!-- 选择 -->
          <div
            class="inputlist input"
            v-else-if="item.metaelementid == 4"
            direction="horizontal"
          >
            <div class="bluecolor">
              {{ item.name }}
              <span v-if="item.must === 1" class="redif">(必选)</span>
            </div>
            <!-- <div v-else>{{ item.id }}-{{ item.value }}</div> -->
            <van-radio-group v-model="item.radio">
              <van-radio
                v-for="list in item.sons"
                :key="list.sortid"
                :name="list.sortid"
                @click="boxlistxia(item, list)"
                >{{ list.name }}</van-radio
              >
            </van-radio-group>
          </div>
          <!-- 下拉 -->
          <div class="inputlist" v-else-if="item.metaelementid == 6">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                {{ item.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
                <span v-if="item.must === 1" class="redif">(必选)</span>
              </span>
              <div v-if="downlist" class="supnup">{{ item.value }}</div>
              <el-dropdown-menu>
                <el-dropdown-item v-for="down in item.sons" :key="down.id">
                  <span>
                    {{ down.name }}
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- 上传的图片 -->
          <div class="inputlist" v-else-if="item.metaelementid == 13">
            <img class="imagelivebao" :src="item.name" alt="" />
          </div>
          <!-- 上传的图片 -->
          <div class="inputlist" v-else-if="item.metaelementid == 1">
            <div class="textlist">{{ item.name }}</div>
          </div>
          <!-- 手机号 -->
          <div class="input" v-else-if="item.metaelementid == 8">
            <div class="bluecolor">
              {{ item.name }}
              <span v-if="item.must === 1" class="redif">(必填)</span>
            </div>
            <input
              ref="textlist"
              class="inputtodo"
              type="text"
              v-model="item.value"
              disabled
            />
          </div>
          <!-- 手机号 -->
          <div class="input" v-else-if="item.metaelementid == 9">
            <div class="bluecolor">
              {{ item.name }}
              <span v-if="item.must === 1" class="redif">(必填)</span>
            </div>
            <input
              ref="textlist"
              class="inputtodo"
              type="text"
              v-model="item.value"
              disabled
            />
          </div>
        </div>
      </div>
      <!-- 修改 -->
      <div class="modify">
        <div class="title">修改字段</div>
        <div class="app" v-for="item in signup" :key="item.id">
          <!-- 填入 -->
          <div class="input flex" v-if="item.metaelementid == 3">
            <!-- 上下 -->
            <div class="topbottom">
              <div @click="toplistbao(item.sortid)">
                <span class="el-icon-top"></span>
              </div>
              <div @click="bottomlistbao(item.sortid)">
                <span class="el-icon-bottom"></span>
              </div>
            </div>
            <!-- 字段名称 -->
            <span class="spantitle">字段名称:</span>
            <input class="spantitle inpuspan" type="text" v-model="item.name" />
            <div>
              <span class="spantitle">是否必填:</span>
              <el-radio-group v-model="item.must">
                <el-radio @click="itemmust(item, 1)" :label="1">是</el-radio>
                <el-radio @click="itemmust(item, 0)" :label="0">否</el-radio>
              </el-radio-group>
            </div>
            <button class="budelet" @click="delet(item.sortid)">删除</button>
          </div>
          <!-- 选择 -->
          <div class="input flex" v-else-if="item.metaelementid == 4">
            <!-- 上下 -->
            <div class="topbottom">
              <div @click="toplistbao(item.sortid)">
                <span class="el-icon-top"></span>
              </div>
              <div @click="bottomlistbao(item.sortid)">
                <span class="el-icon-bottom"></span>
              </div>
            </div>
            <!-- 字段名称 -->

            <div class="list">
              <span class="spantitle">字段名称:</span>
              <input
                class="spantitle inpuspan"
                type="text"
                v-model="item.name"
              />
            </div>
            <div
              class="list"
              v-for="listxuan in item.sons"
              :key="listxuan.sortid"
            >
              <span class="spantitle">选项:</span>
              <input
                class="spantitle inpuspan"
                type="text"
                v-model="listxuan.name"
              />
              <button @click="sendeleter(item.sons, listxuan.sortid)">
                删除
              </button>
            </div>
            <div>
              <span class="spantitle">是否必填:</span>
              <el-radio-group v-model="item.must">
                <el-radio @click="itemmust(item, 1)" :label="1">是</el-radio>
                <el-radio @click="itemmust(item, 0)" :label="0">否</el-radio>
              </el-radio-group>
            </div>
            <button class="budelet add" @click="add(item.sortid, '0')">
              添加子选项
            </button>
            <button class="budelet" @click="delet(item.sortid)">删除</button>
          </div>
          <!-- 下拉 -->
          <div class="input flex" v-else-if="item.metaelementid == 6">
            <!-- 上下 -->
            <div class="topbottom">
              <div @click="toplistbao(item.sortid)">
                <span class="el-icon-top"></span>
              </div>
              <div @click="bottomlistbao(item.sortid)">
                <span class="el-icon-bottom"></span>
              </div>
            </div>
            <!-- 字段名称 -->
            <div class="list">
              <span class="spantitle">字段名称:</span>
              <input
                class="spantitle inpuspan"
                type="text"
                v-model="item.name"
              />
            </div>
            <div class="list" v-for="listform in item.sons" :key="listform.id">
              <span class="spantitle">选项:</span>
              <input
                class="spantitle inpuspan"
                type="text"
                v-model="listform.name"
              />
              <button @click="sendeleter(item.sons, listform.sortid)">
                删除
              </button>
            </div>
            <!-- <div class="list">
              <span class="spantitle">选项二:</span>
              <input
                class="spantitle inpuspan"
                type="text"
                v-model="item.sons[1].name"
              />
            </div> -->
            <div>
              <span class="spantitle">是否必填:</span>
              <el-radio-group v-model="item.must">
                <el-radio @click="itemmust(item, 1)" :label="1">是</el-radio>
                <el-radio @click="itemmust(item, 0)" :label="0">否</el-radio>
              </el-radio-group>
            </div>
            <button class="budelet add" @click="add(item.sortid, '1')">
              添加子选项
            </button>
            <button class="budelet" @click="delet(item.sortid)">删除</button>
          </div>
          <!-- 上传的图片 -->
          <div class="input flex" v-else-if="item.metaelementid == 1">
            <!-- 上下 -->
            <div class="topbottom">
              <div @click="toplistbao(item.sortid)">
                <span class="el-icon-top"></span>
              </div>
              <div @click="bottomlistbao(item.sortid)">
                <span class="el-icon-bottom"></span>
              </div>
            </div>
            <!-- 字段名称 -->
            <span class="spantitle">填写说明:</span>
            <el-input
              type="textarea"
              class="spantitle inpuspan"
              v-model="item.name"
            />

            <button class="budelet" @click="delet(item.sortid)">删除</button>
          </div>
          <!-- 上传的图片 -->
          <div class="input flex" v-else-if="item.metaelementid == 13">
            <!-- 上下 -->
            <div class="topbottom">
              <div @click="toplistbao(item.sortid)">
                <span class="el-icon-top"></span>
              </div>
              <div @click="bottomlistbao(item.sortid)">
                <span class="el-icon-bottom"></span>
              </div>
            </div>
            <!-- 字段名称 -->
            <span class="spantitle">字段名称:</span>
            <input class="spantitle inpuspan" type="text" v-model="item.name" />
            <el-upload
              :http-request="lunbouploadone"
              class="listtoavatar"
              :show-file-list="false"
              :on-success="lunbouploadoneAvatarSuccess"
              :before-upload="beforlunbouploadone"
              :on-change="lunbouploadonelisttolive"
              :auto-upload="false"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <img :src="lunbolist" alt="" />
            </el-upload>
            <button class="budelet" @click="delet(item.sortid)">删除</button>
            <button class="budelet add" @click="addimage(item)">
              使用图片
            </button>
          </div>
          <!-- 填入 -->
          <div class="input flex" v-else-if="item.metaelementid == 8">
            <!-- 上下 -->
            <div class="topbottom">
              <div @click="toplistbao(item.sortid)">
                <span class="el-icon-top"></span>
              </div>
              <div @click="bottomlistbao(item.sortid)">
                <span class="el-icon-bottom"></span>
              </div>
            </div>
            <!-- 字段名称 -->
            <span class="spantitle">字段名称:</span>
            <input class="spantitle inpuspan" type="text" v-model="item.name" />
            <div>
              <span class="spantitle">是否必填:</span>
              <el-radio-group v-model="item.must">
                <el-radio @click="itemmust(item, 1)" :label="1">是</el-radio>
                <el-radio @click="itemmust(item, 0)" :label="0">否</el-radio>
              </el-radio-group>
            </div>
            <button class="budelet" @click="delet(item.sortid)">删除</button>
          </div>
          <div class="input flex" v-else-if="item.metaelementid == 9">
            <!-- 上下 -->
            <div class="topbottom">
              <div @click="toplistbao(item.sortid)">
                <span class="el-icon-top"></span>
              </div>
              <div @click="bottomlistbao(item.sortid)">
                <span class="el-icon-bottom"></span>
              </div>
            </div>
            <!-- 字段名称 -->
            <span class="spantitle">字段名称:</span>
            <input class="spantitle inpuspan" type="text" v-model="item.name" />
            <div>
              <span class="spantitle">是否必填:</span>
              <el-radio-group v-model="item.must">
                <el-radio @click="itemmust(item, 1)" :label="1">是</el-radio>
                <el-radio @click="itemmust(item, 0)" :label="0">否</el-radio>
              </el-radio-group>
            </div>
            <button class="budelet" @click="delet(item.sortid)">删除</button>
          </div>
        </div>
      </div>
      <!-- 下一步 -->
    </div>
    <!-- 下一步 -->
    <div class="buttomtext">
      <button class="buttomtextlist" @click="bottonlist">下一步</button>
    </div>
    <el-dialog v-model="dialogVisible" width="30%" :before-close="handleClose">
      <span>报名表名字：</span>
      <input type="text" v-model="baominglistname" />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="sunbomingname">确认</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 报名表列表 -->
    <!-- 打开关闭报名表 -->
  </div>
</template>
<script>
import { selerbaoming, baomingelete } from '../../../api/index'
import qs from 'qs';
export default {
  data () {
    return {
      dan: { id: 0, baomingbiaoid: 1, metaelementid: 3, name: "姓名", status: 1, parentfield: 0, haschild: 0, sortid: 0, must: 1, value: "" },
      xuan: {
        id: 0, baomingbiaoid: 1, metaelementid: 4, name: "性别", status: 1, parentfield: 0, haschild: 1, sortid: 0, must: 1, radio: '', sons: [
          { id: 0, baomingbiaoid: 1, metaelementid: 5, name: "男", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: 0 },
          { id: 0, baomingbiaoid: 1, metaelementid: 5, name: "女", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: 1 }], value: ""
      },
      xia: {
        id: 0, baomingbiaoid: 1, metaelementid: 6, name: "下拉", status: 1, parentfield: 0, haschild: 1, sortid: 0, must: 1, sons: [
          { id: 0, baomingbiaoid: 1, metaelementid: 5, name: "1", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: 0 },
          { id: 0, baomingbiaoid: 1, metaelementid: 5, name: "2", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: 1 }], value: ""
      },
      imageupdate: { id: 0, baomingbiaoid: 1, metaelementid: 13, name: "图片", status: 1, parentfield: 0, haschild: 0, sortid: 0, must: 0, value: "" },
      phonelive: { id: 0, baomingbiaoid: 1, metaelementid: 8, name: "手机号", status: 1, parentfield: 0, haschild: 0, sortid: 0, must: 1, value: "" },
      textlive: { id: 0, baomingbiaoid: 1, metaelementid: 1, name: "文字介绍", status: 1, parentfield: 0, haschild: 0, sortid: 0, must: 0, value: "" },
      mailboxlive: { id: 0, baomingbiaoid: 1, metaelementid: 9, name: "邮箱", status: 1, parentfield: 0, haschild: 0, sortid: 0, must: 1, value: "" },
      signup: [],
      radio: '',
      list: [
        { sortid: 1, title: '输入字段' },
        { sortid: 2, title: '选择字段' },
        { sortid: 3, title: '下拉字段' },
        { sortid: 4, title: '图片' },
        { sortid: 5, title: '手机号' },
        { sortid: 6, title: '邮箱' },
        { sortid: 7, title: '文字介绍' },
      ],
      downlist: false,
      xuana: { id: 0, baomingbiaoid: 1, metaelementid: 5, name: "男", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: 0 },
      xiaa: { id: 0, baomingbiaoid: 1, metaelementid: 5, name: "1", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: 0 },
      dialogVisible: false,
      // 报名表名字
      baominglistname: '',
      baominglistid: '',
      // 报名表是创建还是提交
      baominglisttodobao: '',
      // 上传的图片
      lunboonetype: '',
      lunboonenamelist: '',
      lunbooneimgfile: '',
      lunbolist: '',
    }
  },
  props: ['install'],
  methods: {
    // 添加
    itemlist (e) {
      console.log(e)
      if (e == 1) {
        let app = {
          id: this.dan.id, baomingbiaoid: this.dan.baomingbiaoid, metaelementid: this.dan.metaelementid, name: this.dan.name, status: this.dan.status, parentfield: this.dan.parentfield, haschild: this.dan.haschild, sortid: this.dan.sortid, must: this.dan.must, value: this.dan.value
        }
        this.signup.push(app)
        for (let i = 0; i < this.signup.length; i++) {
          // d.push(this.a)
          // console.log(i)
          this.signup[i].sortid = i
          console.log(this.signup)
        }
        console.log(this.signup)
      }
      else if (e == 2) {
        let app = {
          id: this.xia.id, baomingbiaoid: 1, metaelementid: 4, name: "性别", status: 1, parentfield: 0, haschild: 1, sortid: '', radio: '', must: 1, sons: [
            { id: 0, baomingbiaoid: 1, metaelementid: 5, name: "男", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: '' },
            { id: 1, baomingbiaoid: 1, metaelementid: 5, name: "女", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: '' }], value: ""
        }
        this.signup.push(app)
        for (let i = 0; i < this.signup.length; i++) {
          // d.push(this.a)
          console.log(i)
          this.signup[i].sortid = i
          // console.log(this.a[i].id, i)
        }
        console.log(this.signup)
      } else if (e == 3) {
        let app = {
          id: this.xia.id, baomingbiaoid: 1, metaelementid: 6, name: "下拉", status: 1, parentfield: 0, haschild: 1, sortid: '', must: 1, sons: [
            { id: 0, baomingbiaoid: 1, metaelementid: 5, name: "1", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: '' },
            { id: 1, baomingbiaoid: 1, metaelementid: 5, name: "2", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: '' }], value: ""
        }
        this.signup.push(app)
        for (let i = 0; i < this.signup.length; i++) {
          // d.push(this.a)
          console.log(i)
          this.signup[i].sortid = i
          // console.log(this.a[i].id, i)
        }
        console.log(this.signup)
      } else if (e == 4) {
        let app = {
          id: this.imageupdate.id, baomingbiaoid: this.imageupdate.baomingbiaoid, metaelementid: this.imageupdate.metaelementid, name: this.imageupdate.name, status: this.imageupdate.status, parentfield: this.imageupdate.parentfield, haschild: this.imageupdate.haschild, sortid: this.imageupdate.sortid, must: this.imageupdate.must, value: this.imageupdate.value
        }
        this.signup.push(app)
        for (let i = 0; i < this.signup.length; i++) {
          // d.push(this.a)
          // console.log(i)
          this.signup[i].sortid = i
          console.log(this.signup)
        }
        console.log(this.signup)
      } else if (e == 5) {
        let app = {
          id: this.phonelive.id, baomingbiaoid: this.phonelive.baomingbiaoid, metaelementid: this.phonelive.metaelementid, name: this.phonelive.name, status: this.phonelive.status, parentfield: this.phonelive.parentfield, haschild: this.phonelive.haschild, sortid: this.phonelive.sortid, must: this.phonelive.must, value: this.phonelive.value
        }
        this.signup.push(app)
        for (let i = 0; i < this.signup.length; i++) {
          // d.push(this.a)
          // console.log(i)
          this.signup[i].sortid = i
          console.log(this.signup)
        }
        console.log(this.signup)
      }
      else if (e == 6) {
        let app = {
          id: this.mailboxlive.id, baomingbiaoid: this.mailboxlive.baomingbiaoid, metaelementid: this.mailboxlive.metaelementid, name: this.mailboxlive.name, status: this.mailboxlive.status, parentfield: this.mailboxlive.parentfield, haschild: this.mailboxlive.haschild, sortid: this.mailboxlive.sortid, must: this.mailboxlive.must, value: this.mailboxlive.value
        }
        this.signup.push(app)
        for (let i = 0; i < this.signup.length; i++) {
          // d.push(this.a)
          // console.log(i)
          this.signup[i].sortid = i
          console.log(this.signup)
        }
        console.log(this.signup)
      } else if (e == 7) {
        let app = {
          id: this.textlive.id, baomingbiaoid: this.textlive.baomingbiaoid, metaelementid: this.textlive.metaelementid, name: this.textlive.name, status: this.textlive.status, parentfield: this.textlive.parentfield, haschild: this.textlive.haschild, sortid: this.textlive.sortid, must: this.textlive.must, value: this.textlive.value
        }
        this.signup.push(app)
        for (let i = 0; i < this.signup.length; i++) {
          // d.push(this.a)
          // console.log(i)
          this.signup[i].sortid = i
          console.log(this.signup)
        }
        console.log(this.signup)
      }
    },
    // 是否必填
    itemmust (e, a) {
      e.must = a
      console.log(e, a)
    },
    // 下拉菜单
    boxlistxia (e, a) {
      console.log(e, a)
      // e.value = a.name
      console.log(e.value)
    },
    // 删除
    delet (e) {
      console.log(e)
      this.signup.splice(e, 1)
      for (let i = 0; i < this.signup.length; i++) {
        // d.push(this.a)
        // console.log(i)
        this.signup[i].sortid = i
        // console.log(this.a[i].id, i)
      }
      console.log(this.signup)
    },
    // 添加
    add (e, a) {
      console.log(e, a)
      if (a == '0') {
        let app = { id: this.xuana.id, baomingbiaoid: 1, metaelementid: 5, name: "男", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: this.xuana.sortid }
        this.signup[e].sons.push(app)
        for (let i = 0; i < this.signup[e].sons.length; i++) {
          // d.push(this.a)
          // console.log(i)
          this.signup[e].sons[i].sortid = i
          // console.log(this.a[i].id, i)
        }
        console.log(this.signup[e], app)
      } else if (a == '1') {
        let app = { id: this.xiaa.id, baomingbiaoid: 1, metaelementid: 5, name: "1", status: 1, parentfield: 2, haschild: 0, must: 1, sortid: this.xiaa.sortid }
        this.signup[e].sons.push(app)
        for (let i = 0; i < this.signup[e].sons.length; i++) {
          this.signup[e].sons[i].sortid = i
        }
      }
    },
    // 删除子选项
    sendeleter (e, a) {
      console.log(e, a)
      e.splice(a, 1)
      for (let i = 0; i < e.length; i++) {
        e[i].sortid = i
      }
      console.log(e)
    },
    // 排序xiang上
    toplistbao (e) {
      console.log(e)
      if (e != 0) {
        let arr = this.signup
        console.log(this.signup)
        arr.splice(e - 1, 1, ...arr.splice(e, 1, arr[e - 1]));
        for (let i = 0; i < this.signup.length; i++) {
          this.signup[i].sortid = i
          console.log(this.signup[i].sortid)
        }
        //   console.log(this.signup)
      } else {
        alert('已经位于第一位')
      }
    },
    // 排序xiang下
    bottomlistbao (e) {
      let app = this.signup.length
      console.log(e)
      if (e == app - 1) {
        alert('已经位于末尾')
      } else {
        let arr = this.signup
        console.log(arr)
        arr.splice(e, 1, ...arr.splice(e + 1, 1, arr[e]));
        //arr.splice(e + 1, 1, ...arr.splice(e, 1, arr[e + 1]));
        //console.log(arr)
        for (var i = 0; i < arr.length; i++) {
          arr[i].sortid = i
        }
      }
      console.log(e)
    },
    // 下一步按钮
    bottonlist () {
      console.log(this.signup)
      this.dialogVisible = true
    },
    // 接受的传值
    listabox () {
      // console.log(this.install)
      if (this.install == '') {
        this.signup = []
        this.baominglisttodobao = this.install
      } else {
        console.log(this.install)
        this.baominglisttodobao = this.install
        this.baominglistid = this.install.id
        this.baominglistname = this.install.title
        let app = qs.stringify({
          baomingbiaoid: this.install.id
        })
        selerbaoming(app).then(res => {
          console.log(res)
          this.signup = res.baomingbiao
        })
      }
      // 显示的值
      // this.signup = this.install
    },
    // 报名表下一步确认
    sunbomingname () {
      console.log(this.baominglisttodobao)
      if (this.baominglisttodobao != '') {
        if (this.baominglistname == '') {
          alert('名字不能为空')
        } else {
          let userid = localStorage.getItem('userid');
          console.log(userid)
          let app = qs.stringify({
            baomingbiaoid: this.baominglistid,
            baomingbiao: this.signup,
            title: this.baominglistname,
            userid: userid
          })
          baomingelete(app).then(res => {
            console.log(res)
            if (res.code == 9999) {
              alert('成功')
              this.signup = []
              this.$emit("childFn", '1')
            }
          })
        }
      } else {
        // 创建提交
        if (this.baominglistname == '') {
          alert('名字不能为空')
        } else {
          let userid = localStorage.getItem('userid');
          console.log(userid)
          let app = qs.stringify({
            baomingbiaoid: this.baominglistid,
            baomingbiao: this.signup,
            title: this.baominglistname,
            userid: userid
          })
          baomingelete(app).then(res => {
            console.log(res.code)
            if (res.code == 9999) {
              alert('成功')
              this.signup = []
              this.$emit("childFn", '1')
            }
          })
        }
      }
    },
    // 上传图片
    // 轮播
    beforlunbouploadone (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 轮播图1进行
    lunbouploadonelisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.lunboonetype = file.raw.type
        this.lunboonenamelist = file.name
        // console.log(111)
        this.lunbooneimgfile = file.raw
        this.lunbouploadoneupload()
        // this.lunboone = URL.createObjectURL(file.raw)
      }
    },
    // 轮播1上传
    lunbouploadoneupload () {
      if (this.lunbooneimgfile != '' && this.lunbooneimgfile != undefined) {
        console.log(this.lunbooneimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.lunbooneimgfile
        let name = this.lunboonenamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          this.lunbolist = 'https://' + data.Location
        });
      } else {
        console.log(123)
      }
    },
    // 使用此图片
    addimage (e) {
      console.log(e)
      e.name = this.lunbolist
      console.log(this.signup)
    }
  },
  created () {
    this.listabox()
  },
}
</script>
<style scoped>
.divlistcolo {
  width: 100%;
  height: 90%;
  box-sizing: content-box;
}
.boxdivlistoff {
  flex: 9;
}
.bottomlist div {
  padding: 10px;
}
/* // 报名表 */

.baoming {
  display: flex;
  width: 90%;
  height: 600px;
  margin: 0 auto;
  border: 1px solid red;
}
/* // 添加 */
.data {
  width: 30%;
  border: 1px solid #ccc;
  background: #ccc;
}
/* // 字段 */
.field {
  width: 60%;
  margin: 0 auto;
}
.fil {
  width: 100%;
  height: 50px;
  margin: 10px auto;
  font-weight: 600;
  line-height: 50px;
  color: #000;
  background: #99ffff;
  text-align: center;
  font-size: 16px;
}
/* // 展示 */
.exhibition {
  width: 40%;
  overflow: auto;
}
/* // 报名表 */
.wited {
  padding: 0 5px;
}
.wited .input {
  width: 90%;
  margin: 10px auto;
}
.inputlist {
  width: 90%;
  margin: 10px auto;
}
.inputlist .van-radio {
  font-size: 15px !important;
  margin: 10px 0;
}
.inputlist input {
  width: 90%;

  padding: 3px;

  font-size: 16px;
  border-radius: 5px;
}
/* 上传的图片展示 */
.inputlist .imagelivebao {
  width: 100%;
}
.inputlist .textlist {
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 4px;
}
/deep/.el-dropdown {
  font-size: 24px;
}
.inputtodo {
  height: 30px;
  width: 90%;
}
.supnup {
  border: 1px solid #000;
  width: 100%;
  font-size: 30px;
  height: 20px;
  padding: 3px;
}
.boxtitle {
  height: 28px;
  font-size: 20px;
  border: 1px solid #ccc;
  width: 100%;
  line-height: 28px;
  margin: 10px 0;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
/* // 提交按钮 */
.bottomsupnup {
  width: 100%;
  text-align: center;
}
.titlelistindex {
}
/* // /报名表必填 */
.redif {
  color: red;
}
.bluecolor {
  font-size: 24px;
  color: #39a9ed;
}
/* // 报名表结束 */
.topbottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
}
/* // 修改 */
.modify {
  width: 40%;
  border: 1px solid #ccc;
  overflow: auto;
}
/* // 名称 */
.title {
  text-align: center;
  color: #000;
  font-size: 26px;
  font-weight: 900;
}
/* // 报名表 */
.spantitle {
  font-size: 16px;
  color: #000;
}
.inpuspan {
  width: 60%;
}
/* // */
.app {
  border: 1px solid #ccc;
}
.list {
  /* // height: 30px; */
}
/* // */
/* // 选择 */
.el-radio-button__inner,
.el-radio-group {
  margin: 18px 0 0 3px;
}
/* // 删除 */
.budelet {
  height: 40px;
  width: 80px;
  font-size: 10px;
  border: none;
  background: red;
  color: #fff;
  display: inline-block;
  margin: 0 25px;
}
.add {
  background: #39a9ed;
  border: 1px solid #409eff;
}
/* // 提交 */
.buttomtext {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.buttomtextlist {
  width: 160px;
  height: 30px;
  font-size: 15px;
}
/* 图片 */
.listtoavatar {
  width: 100%;
  margin: 2px;
}
.listtoavatar img {
  width: 100%;
  height: 100%;
}
</style>
