<template>
  <div>
    <!-- 点击按钮 -->
    <div class="box">
      <div>
        <el-button @click="boming" v-if="box" type="primary"
          >添加报名表</el-button
        >
        <el-button @click="boming" v-else type="danger">取消/返回</el-button>
      </div>
    </div>
    <!-- 表格显示报名表 -->
    <div class="title" v-if="box">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="id" label="序号" width="180"> </el-table-column>
        <el-table-column prop="title" label="姓名" width="180">
        </el-table-column>
        <el-table-column label="更改">
          <template v-slot="scope">
            <el-button type="text" @click="delet(scope.row)">删除</el-button>
            <el-button type="text" @click="editem(scope.row)">编辑</el-button>
            <el-button type="text" @click="copy(scope.row)">复制</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 报名表 -->
    <div class="title" v-else>
      <regist @childFn="parentFn" :install="install" :index="com"></regist>
    </div>
  </div>
</template>
<script>
import regist from './compents/index.vue'
import qs from 'qs';
import { baoming, deletebaoming, copybaomingbiao } from '../../api/index'
export default {
  data () {
    return {
      box: true,
      tableData: [
      ],
      // 父传子
      install: '',
      // key的值
      com: '',
    }
  },
  methods: {
    // 页面加载
    listbaoming () {
      var userid = localStorage.getItem('userid');
      let app = qs.stringify({
        userid: userid,
      })
      baoming(app).then(res => {
        // console.log(res)
        this.tableData = res.baomingbiaolist
      })
    },
    // 报名表点击添加
    boming () {
      this.box = !this.box
      this.com = new Date().getTime()
      this.install = ''
    },
    // 编辑
    editem (e) {
      console.log(e)
      this.com = new Date().getTime()
      this.install = e
      this.box = false
    },
    // 删除
    delet (e) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let app = qs.stringify({ baomingbiaoid: e.id })
        deletebaoming(app).then(res => {
          console.log(res)
          if (res.code == 9999) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.listbaoming()
          } else {
            alert('失败')
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

      // console.log(e)
    },
    // 复制
    copy (e) {
      console.log(e)
      let app = qs.stringify({ baomingbiaoid: e.id })
      copybaomingbiao(app).then(res => {
        console.log(res)
        if (res.code == '9999') {
          this.listbaoming()
        } else {
          this.$message({
            type: 'info',
            message: '错误'
          });
        }
      })
    },
    // 子传值编辑完成
    parentFn (e) {
      console.log(e)
      if (e == '1') {
        this.box = true
        this.listbaoming()
        // console.log('123')
      }
    }
  },
  components: {
    regist
  },
  created () {
    this.listbaoming()
  }
}
</script>
<style scoped>
.box {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
</style>
