<template>
  <div class="from">
    <!-- 头部 -->
    <div class="nav">
      <div class="create">
        <div class="tutorials" @click="channel">创建频道</div>
        <div class="applist">频道教程</div>
      </div>
      <div class="text">
        <div>分类管理</div>
        |
        <div>回收站</div>
      </div>
    </div>
    <!-- 头部结束 -->
    <!-- 中间 -->
    <div class="crent">
      <!-- 第一行 -->
      <div class="title">
        <div class="list">
          <div class="fromlist">
            <el-dropdown>
              <span class="el-dropdown-link">
                频道名称<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item icon="el-icon-plus" @click="list"
                    >频道名称</el-dropdown-item
                  >
                  <el-dropdown-item icon="el-icon-circle-plus" @click="listid"
                    >频道id</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="input">
            <el-input v-model="input" :placeholder="inputts"></el-input>
          </div>
        </div>
        <div class="status">
          <div class="fromlist">
            <el-dropdown>
              <span class="el-dropdown-link"> 频道类型</span>
            </el-dropdown>
          </div>

          <div class="inputs">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ app }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu v-for="item in items" :key="item.is">
                  <el-dropdown-item @click="displaylist(item.id, item.title)">{{
                    item.title
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <div class="times">
          <div class="fromlist">
            <el-dropdown>
              <span class="el-dropdown-link"> 开始时间： </span>
            </el-dropdown>
          </div>
          <div class="start">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <!-- 第一行结束 -->
      <!-- 第二行 -->
      <div class="list">
        <div class="status asd">
          <div class="fromlist">
            <el-dropdown>
              <span class="el-dropdown-link"> 频道状态 </span>
            </el-dropdown>
          </div>
          <div class="inputs width">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ listtop }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>{{ listtop }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>

        <div class="status">
          <div class="fromlist">
            <el-dropdown>
              <span class="el-dropdown-link"> 显示状态:</span>
            </el-dropdown>
          </div>
          <div class="inputs">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ filt }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu v-for="item in itemlist" :key="item.id">
                  <el-dropdown-item @click="filstlist(item.id, item.title)">{{
                    item.title
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
      <!-- 第二行结束 -->
      <!-- 第三行 -->
      <div class="treelist">
        <el-button type="primary">筛选</el-button>
        <el-button>清除搜索条件</el-button>
      </div>
      <!-- 第三行结束 -->
      <!-- 第四行 -->

      <!-- 第四行结束 -->
    </div>
    <!-- 中间结束 -->
    <!-- 底部编辑 -->
    <div class="editing">
      <div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="频道id" width="100">
            <template #default="scope">{{ scope.row.num }}</template>
          </el-table-column>
          <el-table-column label="频道封面" width="100">
            <template #default="scope">
              <img
                class="asa"
                :src="scope.row.imgscr"
                style="width: 60px; height: 30px"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column prop="name" label="频道名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="address"
            width="110"
            label="频道类型"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="status"
            width="120"
            label="频道状态"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="end"
            width="120"
            label="显示状态"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="times"
            width="120"
            label="开始时间"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            width="120"
            prop="classification"
            label="频道分类"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            width="120"
            prop="nmber"
            label="浏览量"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column prop="listlist" label="排序" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="操作" show-overflow-tooltip width="200">
            <template #default="scope">
              <el-button type="text" @click="open(scope.row)">编辑</el-button>
              <el-button type="text" @click="centerDialogVisible = true"
                >分享</el-button
              >
              <el-button type="text">更多</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog
        v-model="centerDialogVisible"
        width="30%"
        destroy-on-close
        center
      >
        <div class="boxred">
          <span class="titles">二维码</span>
          <img src="../../images/1.png" alt="" />
          <img src="../../images/1.png" alt="" />
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// import { zhibo } from '../api/index'
export default {
  // name: dropdown,
  data () {
    return {
      // dropdown: 'dropdown'
      items: [
        { id: 1, title: '全部' },
        { id: 2, title: '未开始' },
        { id: 3, title: '进行中' },
        { id: 4, title: '正在推流' },
        { id: 5, title: '一结束' },
        { id: 6, title: '已失效' },
      ],
      item: [
        { id: 1, title: 'vr频道' },
        { id: 2, title: '视频频道' },
        { id: 3, title: '鱼片频道' },
        { id: 4, title: '图片频道' },
        { id: 5, title: '语音频道' },
        { id: 6, title: '全部' }

      ],
      itemlist: [
        { id: 1, title: '上架' },
        { id: 2, title: '下架' },
        { id: 3, title: '全部' },

      ],
      app: '全部',
      add: '全部',
      filt: '全部',
      input: '',

      inputts: '请输入',
      status: '',
      listtop: '全部',
      checked: false,
      tableData: [
        {
          id: 1,
          num: '1231456',
          name: '王小虎',
          address: '开',
          status: '视频频道',
          times: '2020-01.05',
          end: '结束',
          classification: '无',
          nmber: 3,
          listlist: '9',
          imgscr: ['https://img0.baidu.com/it/u=1999921673,816131569&fm=26&fmt=auto&gp=0.jpg'],
        },
        {
          id: 2,
          num: '16588',
          name: '王小虎',
          address: '开',
          status: '视频频道',
          times: '2020-01.05',
          end: '结束',
          classification: '无',
          nmber: 3,
          listlist: '9',
          imgscr: 'https://img0.baidu.com/it/u=1999921673,816131569&fm=26&fmt=auto&gp=0.jpg',
        }
      ],
      centerDialogVisible: false
    }
  },
  methods: {
    list () {
      this.inputts = '请输入'
      // if (subItem.id == 1) {
      this.inputts = this.inputts.concat('频道名称')
      console.log(this.inputts)
      // }
      // console.log(this.items)
    },
    listid () {
      this.inputts = '请输入'
      // if (subItem.id == 1) {
      this.inputts = this.inputts.concat('频道id')
      console.log(this.inputts)
      // }
      // console.log(this.items)
    },
    displaylist (e, c) {
      // if(e==1){
      //   this.app=
      // }
      console.log(e)
      console.log(c)
      this.app = c
    },
    displaylists (e, c) {
      // if(e==1){
      //   this.app=
      // }
      console.log(e)
      console.log(c)
      this.add = c
    },
    filstlist (a, b) {
      // if(e==1){
      //   this.app=
      // }
      console.log(a)
      console.log(b)
      this.filt = b
    },
    // 跳转
    channel () {
      this.$router.push('/channel')
    },
    // 消息提示
    open (e) {
      // let res = this.tableData
      console.log(e)
      this.$router.push(
        {
          name: "editing",
          params: {
            id: e.id,
            num: e.num,
            name: e.name,
            img: e.imgscr
          }
        }
      )
    },
    management () {
      this.$router.push('/management')
    }
    // async porp () {
    //   await zhibo(111).then(res => {
    //     console.log(res.data)
    //     this.items = res.data
    //   })
    // }
  },

}
</script>
<style>
/* 头部 */
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}
.create {
  display: flex;
  font-size: 14px;
}
.tutorials {
  padding: 5px;
  background-color: #006cff;
  /* font-weight: 900; */
  color: white;
}
.tutorials:hover {
  cursor: pointer;
}
.applist {
  padding: 5px;
}
.applist:hover {
  cursor: pointer;
}
.text {
  display: flex;
}
.text div {
  margin: 0 5px;
  font-size: 14px;
}
.text div:hover {
  cursor: pointer;
}
/* 头部结束 */
/* 中间部分 */
.el-input {
  left: 0 !important;
}
.crent {
  margin-top: 30px;
}
.fromlist {
  width: 80px;
  margin-top: 20px;
}
.input {
  width: 200px;
}
.title {
  display: flex;
}
.list {
  display: flex;
  /* flex: 3; */
}
.el-input {
  top: 9px;
  left: 10px;
}
.status {
  /* flex: 3; */
  display: flex;
  margin-left: 20px;
}
.inputs {
  margin-top: 10px;
  width: 160px;
  padding: 10px;
  border: 1px solid #ccc;
}
.el-dropdown {
  /* height: 100%; */
  /* text-align: center; */
  width: 100%;
  line-height: 20px;
}
.times {
  padding: 0 20px;
  display: flex;
}
.start {
  margin-top: 10px;
  /* line-height: 40px; */
}
.asd {
  margin-left: 0 !important;
}
.width {
  width: 180px !important;
}
.app {
  margin-right: 10px;
}
.box {
  margin-left: 9px !important;
}
.el-range-editor.el-input__inner {
  width: 500px;
}
/* 第三行 */
.treelist {
  margin-top: 20px;
  margin-left: 90px;
}
.el-button--primary {
  background-color: #006cff;
  border: 1px solid #006cff;
}
/* 第四行 */
.forlist {
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px dashed #ccc;
  border-bottom: 1px dashed #ccc;
  display: flex;
}
.fourlist .el-dropdown {
  margin-top: 13px;
}
.fourbuttom {
  margin-left: 15px;
}
/* 显示隐藏 */
.titles {
  font-size: 16px;
  font-weight: 900;
  color: red;
  display: block;
  text-align: center;
}
.boxred img {
  width: 180px;
  height: 180px;
  margin: 15px;
}
</style>